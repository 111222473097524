// Path : app/javascript/components/GreetUser.js
import React from "react";
import PropTypes from "prop-types";

function ApiDetails( { id, address} ) {
  return (
    
      <div className="widget">
        <div className="title">
          <img className="titleIcon" alt="" src="/images/list.png" />
          <h6>API Details - <small>Visible To Super Admin Only</small></h6>
        </div>
        <div className="dataTables_wrapper p-2" id="api_wrapper">
          <ul>
            <li>Report: { address }/report/{ id }.json </li>
            <li>Analytics: { address }/analytics/{ id }.json </li>
            <li>Jobs: { address }/jobs/{ id }.json </li>
            <li>Forms: { address }/emails/{ id }.json </li>
            <li>Calls: { address }/calls/{ id }.json </li>
          </ul>
        </div>
      </div>
    
  );

}

ApiDetails.propTypes = {
  id: PropTypes.string
};

export default ApiDetails;