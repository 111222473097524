// Path : app/javascript/components/GreetUser.js
import React from "react";
import PropTypes from "prop-types";

function Loading( { name } ) {
  return (
    <div className="textC p-2 grey-text">
      <i className="fas fa-spinner fa-3x fa-spin"></i>
    </div>
  );

}

Loading.propTypes = {
  name: PropTypes.string
};

export default Loading;