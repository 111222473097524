import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import axios from "axios";
import moment from "moment";
import Loading from "./Loading";

import { PieChart } from 'react-minimal-pie-chart';


function reportAnalytics(  { id, address}  ) {
  const [analytics, setAnalytics] = useState( [] )
  const [analyticsStats, setAnalyticsStats] = useState( [] )
  const [analyticDevice, setAnalyticsDevice] = useState( [] )
  const [analyticCities, setAnalyticCities] = useState( [] )
  const [analyticSource, setAnalyticSource] = useState( [] )
  const [analyticMedium, setAnalyticMedium] = useState( [] )
  

  useEffect( () => {
    console.log("Analytics mounted")
    getAnalytics()
  }, []);

  const getAnalytics = async () => {
    try {
      const resp = await axios({
        method: 'GET',
        url: address + "/analytics/" + id + ".json"
      });
      
      console.log( resp.data )
      setAnalytics( resp.data.days );
      setAnalyticsStats( resp.data.stats );
      setAnalyticsDevice( resp.data.device );
      setAnalyticCities( resp.data.city );
      setAnalyticSource( resp.data.source );
      setAnalyticMedium( resp.data.medium );

    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  }

  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div>
      <React.Fragment>
        { analytics ?
          <div>
            <div className="widget chartWrapper">
              <a name="googleAnalytics"></a>
              <div className="title"><h6>Visits</h6></div>
              <div className="body">
                
              </div> 
            </div>

            <div className="clear"></div>

            <div className="widget nobreak widget-chart" >
              <div className="title"><h6>Visits by Traffic Type</h6></div>
              <div className="body chart" >
                <PieChart
                  data={ analyticMedium }
                  label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%' }
                  labelStyle={(index) => ({
                    fill: '#fff',
                    fontSize: '8px',
                    fontFamily: 'sans-serif',
                  })}
                />
              </div>

              <div id="legend" className="widget-legend">
                <table>
                  <tbody>
                    { analyticMedium.map( source => {
                      return (
                        <tr key={source.title} className='p-2'>
                          <td className="legendColorBox">
                            <div>
                              <div className={source.class}></div>
                            </div>
                          </td>
                          <td className="legendLabel"><strong>{source.title}</strong><br />{source.value }</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="clear"></div>

          
            <div className="widgets">
              <div className="oneThree">
                <div className="widget nobreak">
                  <div className="title"><h6>Website Statistics</h6></div>
                  <div className="wStats">
                    <ul>
                      <li><h4 className="blue">{ analyticsStats.visits }</h4><span>Visits</span></li>
                      <li><h4 className="blue">{ analyticsStats.visitors }</h4><span>Unique Visitors</span></li>
                      <li><h4 className="blue">{ analyticsStats.pageviews }</h4><span>Pageviews</span></li>
                      <div className="linesep"></div>
                      <li><h4 className="blue">{ analyticsStats.pagespervisit }</h4><span>Pages/Visit</span></li>
                      <li><h4 className="blue">{ analyticsStats.visitduration }</h4><span>Avg. Visit Duration</span></li>
                      <li><h4 className="blue">{ analyticsStats.pnewvisits }</h4><span>% New Visits</span></li>
                    </ul>
                  </div>
                </div>
                <div className="clear"></div>

                <div className="widget nobreak">
                  <div className="title"><h6>Visits by Device</h6></div>
                  <table cellPadding="0" cellSpacing="0" width="100%" className="sTable stat-tables">
                    <thead>
                      <tr>
                        <td>Device</td>
                        <td>Visits</td>
                      </tr>
                    </thead>
                    <tbody>
                      { analyticDevice.map( (device, index) => {
                        return (
                          <tr key={index}>
                            <td>{ capitalizeFirst( device.dimensions[0] ) }</td>
                            <td>{device.metrics[0].values[0]}</td>
                          </tr>
                        )})
                      }
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="oneThree">
                <div className="widget nobreak">
                  <div className="title"><h6>Visits by City</h6></div>
                  <table cellPadding="0" cellSpacing="0" width="100%" className="sTable stat-tables">
                    <thead>
                      <tr>
                        <td>Device</td>
                        <td>Visits</td>
                      </tr>
                    </thead>
                    <tbody>
                      { analyticCities.map( (city, index) => {
                        return (
                          <tr key={index}>
                            <td>{ capitalizeFirst( city[0] ) }</td>
                            <td>{ city[1] }</td>
                          </tr>
                        )})
                      }
                    </tbody>
                  </table>
                </div>
                <div className="clear"></div>
              </div>

              <div className="oneThree">
                <div className="widget nobreak">
                  <div className="title"><h6>Visits by Source</h6></div>
                  <table cellPadding="0" cellSpacing="0" width="100%" className="sTable stat-tables">
                    <thead>
                      <tr>
                        <td>Device</td>
                        <td>Visits</td>
                      </tr>
                    </thead>
                    <tbody>
                      { analyticSource.map( (source, index) => {
                        return (
                          <tr key={index}>
                            <td>{ source[0] }</td>
                            <td>{ source[1] }</td>
                          </tr>
                        )})
                      }
                    </tbody>
                  </table>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          
          : <Loading />
        }
      </React.Fragment>
      
    </div>
  );
}

reportAnalytics.propTypes = {
  id: PropTypes.string
};

export default reportAnalytics

function formatClassification( id, classifications ) {
  var theName = classifications.filter(function(item) {
    return item.id === parseInt( id );
  })[0];
  
  return theName.name
}

function formatCurrency( number ) {
  return '$ ' + ( parseInt( number ) / 100 ).toFixed(2)
}

function formatTime( time ) {
  return moment(new Date( time )).format('MMM DD, YYYY h:mm:ss a') 
}

function formatNumber( phone ) {
  let cleaned = ('' + phone).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };

  return null
}

function sortFunction(a, b) {
  if (a[0] === b[0]) {
    return 0;
  } else {
    return (a[0] < b[0]) ? -1 : 1;
  }
}