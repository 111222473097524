import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import axios from "axios";
import moment from "moment";
import Loading from './Loading';

function ReportCalls(  { id, address}  ) {
  const [calls, setCalls] = useState( [] )
  const [callGroups, setCallGroups] = useState( [] )
  const [classifications, setClassifications] = useState( [] )
 
  useEffect( () => {
    console.log("Calls mounted")
    getCalls()
  }, []);

  const getCalls = async () => {
    try {
      const resp = await axios({
        method: 'GET',
        url: address + "/calls/" + id + ".json"
      });
      
      let groups = resp.data.current.reduce(function (r, a) {
        r[a.call_classification_id] = r[a.call_classification_id] || [];
        r[a.call_classification_id].push(a);
        return r;
      }, []);

      setCalls( resp.data.current );
      setClassifications( resp.data.classifications.calls )
      setCallGroups( groups );
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  }

  return (
    <div>
      <React.Fragment>
        { calls && calls.length > 0 ?
          <div className="widgets">
            <a name="phoneCalls"></a>
            <div className="widget nobreak">
              <div className="title"><h6>Phone Call Categories</h6></div>

              <table cellPadding="0" cellSpacing="0" width="100%" className="sTable">
                <thead>
                  <tr>
                    <td>Classification</td>
                    <td>Count</td>
                    
                  </tr>
                </thead>
                <tbody>
                  
                  { callGroups.map( (call, index) => {
                    return (
                      <tr key={index}>
                        <td>{formatClassification( index, classifications )}</td><td>{call.length }</td>
                      </tr>
                    )})
                  }
                  
                </tbody>
                <tfoot>
                  <tr>
                    <td><strong>Total:</strong></td>
                    <td><strong>{ calls.length }</strong></td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div className="widget nobreak">
              <div className="title"><h6>Phone Call Report</h6></div>
              <table cellPadding="0" cellSpacing="0" width="100%" className="sTable">
                <thead>
                  <tr>
                    <td>Date</td>
                    <td>Name</td>
                    <td>Category</td>
                    <td>Details</td>
                    <td>Appointment Scheduled</td>
                    <td>Previous Customer</td>
                    <td>Audio</td>
                  </tr>
                </thead>
                <tbody>
                  { calls.map( call => {
                    return (
                      <tr key={call.id} >
                        <td>{ formatTime( call.call_time ) }</td>
                        <td>
                          <div>{call.firstname}&nbsp;{call.lastname}</div>
                          <div>{ formatNumber( call.callers_number ) }</div>
                          <div>{call.streetaddress && call.streetaddress} {call.secondaryaddress && call.secondaryaddress} {call.city} {call.state} {call.zip}</div>
                        </td>
                        <td>{formatClassification( call.call_classification_id, classifications ) }</td>
                        <td>{call.call_details}</td>
                        <td>{call.call_appointment_option && call.call_appointment_option.name}</td>
                        <td>{call.past_customer}</td>
                        <td>
                          { call.ctn.call_provider === "callrail" 
                            ? <>
                            { call.voice_file !== "" && call.voice_file !== null
                              ? <a href={ '/call-audio/' + call.call_id } target="_blank" className="ryno-orange"><i className="fa fa-file-audio-o fa-2x"></i></a>
                              : 'No Audio'
                            }
                            </>
                            : <>
                              { call.voice_file !== "" && call.voice_file !== null
                                ? <a href={ call.voice_file } target="_blank" className="ryno-orange"><i className="fa fa-file-audio-o fa-2x"></i></a>
                                : 'No Audio'
                              }
                            </>
                          }
                        </td>
                      </tr> 
                    )
                  })}
                </tbody>
                
              </table>
              <div className="clear"></div>
            </div>
          </div>
          
          : <Loading />
        }
      </React.Fragment>
    </div>
  );
}

ReportCalls.propTypes = {
  id: PropTypes.string
};

export default ReportCalls



function formatClassification( id, classifications ) {
  //let theName = 'Test' //classifications.filter(classification => classification['id == id)
  var theName = classifications.filter(function(item) {
    return item.id === id;
})[0];
  
  return theName.name

}

function formatTime( time ) {
  return moment(new Date( time )).format('MMM DD, YYYY h:mm:ss a') 
}

function formatNumber( phone ) {
  let cleaned = ('' + phone).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };

  return null
}

function sortFunction(a, b) {
  if (a[0] === b[0]) {
      return 0;
  }
  else {
      return (a[0] < b[0]) ? -1 : 1;
  }
}