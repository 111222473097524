import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import axios from "axios";
import moment from "moment";
import Loading from "./Loading";

function ReportForms(  { id, address}  ) {
  const [emails, setEmails] = useState( [] )
  const [emailGroups, setEmailGroups] = useState( [] )
  const [classifications, setClassifications] = useState( [] )
 
  useEffect( () => {
    console.log("Forms mounted")
    getForms()
  }, []);

  const getForms = async () => {
    try {
      const resp = await axios({
        method: 'GET',
        url: address + "/emails/" + id + ".json"
      });
      
      let groups = resp.data.current.reduce(function (r, a) {
        r[a.category] = r[a.category] || [];
        r[a.category].push(a);
        return r;
      }, []);

      setEmails( resp.data.current );
      setClassifications( resp.data.classifications.emails )
      setEmailGroups( groups );
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  }

  return (
    <div>
      <React.Fragment>
        { emails && emails.length > 0 ?
          <div className="widgets">
            <a name="contactForms"></a>
            <div className="widget nobreak">
              <div className="title"><h6>Contact Form Categories</h6></div>

              <table cellPadding="0" cellSpacing="0" width="100%" className="sTable">
                <thead>
                  <tr>
                    <td>Classification</td>
                    <td>Count</td>
                    
                  </tr>
                </thead>
                <tbody>
                  
                  { emailGroups.map( (email, index) => {
                    return (
                      <tr key={index}>
                        <td>{formatClassification( index, classifications )}</td><td>{email.length }</td>
                      </tr>
                    )})
                  }
                  
                </tbody>
                <tfoot>
                  <tr>
                    <td><strong>Total:</strong></td>
                    <td><strong>{ emails.length }</strong></td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div className="widget nobreak">
              <div className="title"><h6>Contact Form Report</h6></div>
              <table cellPadding="0" cellSpacing="0" width="100%" className="sTable">
                <thead>
                  <tr>
                    <td>Date</td>
                    <td>Contact</td>
                    <td>Category</td>
                    <td>Details</td>
                  </tr>
                </thead>
                <tbody>
                  { emails.map( email => {
                    return (
                      <tr key={email.id} >
                        <td>{ formatTime( email.created_at ) }</td>
                        <td>
                          <div>{email.name}</div>
                          <div>{email.from_email}</div>
                          <div>{ formatNumber( email.phone ) }</div>
                        </td>
                        <td>{formatClassification( email.category, classifications ) }</td>
                        <td>{email.message}</td>
                      </tr> 
                    )
                  })}
                </tbody>
                
              </table>
              <div className="clear"></div>
            </div>
          </div>
          
          : <Loading />
        }
      </React.Fragment>
    </div>
  );
}

ReportForms.propTypes = {
  id: PropTypes.string
};

export default ReportForms



function formatClassification( id, classifications ) {
  //let theName = 'Test' //classifications.filter(classification => classification['id == id)
  var theName = classifications.filter(function(item) {
    return item.id === parseInt( id );
})[0];
  
  return theName.name

}

function formatTime( time ) {
  return moment(new Date( time )).format('MMM DD, YYYY h:mm:ss a') 
}

function formatNumber( phone ) {
  let cleaned = ('' + phone).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };

  return null
}

function sortFunction(a, b) {
  if (a[0] === b[0]) {
      return 0;
  }
  else {
      return (a[0] < b[0]) ? -1 : 1;
  }
}