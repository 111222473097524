import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import axios from "axios";
import moment from "moment";
import Loading from "./Loading";

import { PieChart } from 'react-minimal-pie-chart';


function ReportJobs(  { id, address}  ) {
  const [jobs, setJobs] = useState( [] )
  const [classifications, setClassifications] = useState( [] )
  const [sources, setSources] = useState( [] )

  useEffect( () => {
    console.log("Jobs mounted")
    getJobs()
  }, []);

  const getJobs = async () => {
    try {
      const resp = await axios({
        method: 'GET',
        url: address + "/jobs/" + id + ".json"
      });
      
      console.log( resp.data )
      setJobs( resp.data.current );
      setSources( resp.data.source );
      setClassifications( resp.data.classifications.calls )
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  }

  return (
    <div>
      <React.Fragment>
        { jobs ?
          <div className="widgets">
            <a name="jobRevenue"></a>
            <div className="widget nobreak">
              <div className="oneThree">
                <div className="widget chartWrapper">
                  <div className="title"><h6>Revenue By Source</h6></div>
                  <div className="body">
                    <PieChart
                      data={ sources }
                      label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%'}
                      labelStyle={(index) => ({
                        fill: '#fff',
                        fontSize: '8px',
                        fontFamily: 'sans-serif',
                      })}
                    />
                  </div>

                  <div id="legend" className='p-2'>
                    <table cellPadding="0" cellSpacing="0" width="100%">
                      <tbody>
                        { sources.map( source => {
                          return (
                            <tr key={source.title} className='p-2'>
                              <td className="legendColorBox">
                                <div>
                                  <div className={source.class}></div>
                                </div>
                              </td>
                              <td className="legendLabel"><strong>{source.title}</strong><br />${source.value }</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <br />
              </div>

              <div className="twoOne">
                <div className="widget">
                  <div className="title"><h6>Revenue By Day</h6></div>
                  <div className="body">
                    <canvas id="revenueLineChart"></canvas>
                  </div>
                </div>
              </div>
            </div>

            <div className="widget nobreak">
              <div className="title"><h6>Job Tracking Information</h6></div>
              <table cellPadding="0" cellSpacing="0" width="100%" className="sTable">
                <thead>
                  <tr>
                    <td>Date</td>
                    <td>Job Description</td>
                    <td>Lead Information</td>
                    <td>Revenue</td>
                  </tr>
                </thead>
                <tbody>
                  { jobs.map( job => {
                    return (
                      <tr key={job.id} >
                        <td>{ formatTime( job.date ) }</td>
                        <td>
                          { job.description }
                        </td>
                        <td>
                          <div>{job.source}</div>
                          <div><strong>{ job.lead_type } Information</strong></div>
                          <div>Name: <strong>{ job.name }</strong></div>
                          <div>Number: <strong>{ job.number }</strong></div>
                          <div>Address: <strong>{ job.address }</strong></div>
                          <div>Classification: <strong>{ formatClassification( job.classification, classifications ) }</strong></div>
                        </td>
                        <td>{ formatCurrency( job.total_amount ) }</td>
                      </tr>
                    )
                  })}
                </tbody>
                
              </table>
              <div className="clear"></div>
            </div> 
          </div>
          
          : <Loading />
        }
      </React.Fragment>
      
    </div>
  );
}

ReportJobs.propTypes = {
  id: PropTypes.string
};

export default ReportJobs

function formatClassification( id, classifications ) {
  var theName = classifications.filter(function(item) {
    return item.id === parseInt( id );
  })[0];
  
  return theName.name
}

function formatCurrency( number ) {
  return '$ ' + ( parseInt( number ) / 100 ).toFixed(2)
}

function formatTime( time ) {
  return moment(new Date( time )).format('MMM DD, YYYY h:mm:ss a') 
}

function formatNumber( phone ) {
  let cleaned = ('' + phone).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };

  return null
}

function sortFunction(a, b) {
  if (a[0] === b[0]) {
    return 0;
  } else {
    return (a[0] < b[0]) ? -1 : 1;
  }
}