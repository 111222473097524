import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import axios from "axios";
import moment from "moment";
import Loading from "./Loading";

function ReportHeader(  { id, address}  ) {
  const [reportData, setReportData] = useState( [] )
  const [accountData, setAccountData] = useState( [] )
  const [analyticsData, setAnalyticsData] = useState( [] )

  useEffect( () => {
    console.log("Report mounted")
    getReport()
  }, []);

  const getReport = async () => {
    try {
      const resp = await axios({
        method: 'GET',
        url: address + "/report/" + id + ".json"
      });
      
      setReportData( resp.data.report );
      setAccountData( resp.data.account )
      setAnalyticsData( resp.data.analytics )

      console.log(resp.data );
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  }

  return (
    <div>
      {reportData && reportData != [] ?
        <React.Fragment>
          <div>
            <div className="titleArea-preview" data-version="2020">
              <div className="pageTitle">
                <h1>
                  { accountData && accountData.name }
                  { reportData && reportData.publish_date === null 
                    ? "<span className='not-published'>**NOT PUBLISHED**</span>"
                    : ""
                  }
                </h1>
                
                <div className="performace-report">{ reportData && moment.utc(new Date( reportData.report_date )).format('MMMM YYYY') } Campaign Performance Report</div>
              </div>

              <div className="middleNav">
                <img src={ accountData && accountData.logo } className="company-logo" alt="Client Logo" />
                <div className="clear"></div>
              </div>

              <div className="clear"></div>
              <hr />
              <div className="row">
                <div className="medium-12 small-6 columns" data-sticky-container >
                  <div className="sticky" data-sticky data-anchor="report">
                    <ul className="jumpMenu">
                      <li className="menu-label"><strong>Jump To: </strong></li>
                      <li><a href="#monthlyStats">Monthly Statistics</a></li>
                      <li><a href="#googleAnalytics">Google Analytics</a></li>
                      <li><a href="#jobRevenue">Job Revenue</a></li>
                      <li><a href="#contactForms">Contact Forms</a></li>
                      <li><a href="#phoneCalls">Phone Calls</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            { reportData && (
              <div className="widget">
                <a name="monthlyStats"></a>
                <div className="title"><h6>Report Summary</h6></div>
                <div className="p-2" dangerouslySetInnerHTML={{ __html: reportData.report_summary_display }} />
              </div>
            )}

            <div className="widget">
              <div className="title"><h6>Monthly Stats</h6><div  className="num">Report Range: { reportData.report_range }</div></div>
              <table cellPadding="0" cellSpacing="0" border="0" width="100%"  className="sTable">
                <thead>
                  <tr>
                    <td><a href="#" title="Unique Visits: Unique IP Addresses"  className="tipS">Unique Visits</a></td>
                
                    <td><a href="#" title="Total Calls: All calls received on RYNO call tracking numbers (CTN)"  className="tipS">Total Calls</a></td>
                    <td><a href="#" title="RYNO Calls: Calls generated from RYNO marketing efforts"  className="tipS">RYNO Calls</a></td>
                    <td><a href="#" title="Missed Calls: Calls that went unanswered"  className="tipS">Missed Calls</a></td>
                
                    <td><a href="#" title="Total Contact Forms: All contacts forms submitted"  className="tipS">Total Contact Forms</a></td>
                    <td><a href="#" title="RYNO Contact Forms: Forms generated from RYNO marketing efforts"  className="tipS">RYNO Contact Forms</a></td>

                    <td><a href="#" title="RYNO Leads: The total of RYNO calls and RYNO campaign forms minus missed calls"  className="tipS">RYNO Leads</a></td>

                    <td><a href="#" title="Conversion Rate: Percentage of unique website visitors that resulted in a RYNO lead"  className="tipS">Conversion Rate</a></td>
                    <td><a href="#" title="Booking Rate: Percentage of RYNO calls that resulted in an appointment set on the same call"  className="tipS">Booking Rate</a></td>
                    <td><a href="#" title="Cost Per Lead: The cost range per RYNO generated lead"  className="tipS">Cost Per Lead</a></td>
                  </tr>
                </thead>
                <tbody  className="main_summary">
                  { reportData && ( 
                    <tr>
                      <td align="center">{ analyticsData.visitors }</td>
                      
                      <td align="center">{ reportData.total_calls }</td>
                      <td align="center">{ reportData.campaign_calls }</td>
                      <td align="center">{ reportData.missed_calls }</td>
                    
                      <td align="center">{ reportData.total_contact_forms }</td>
                      <td align="center">{ reportData.total_campaign_forms }</td>
                      
                      <td align="center">{ reportData.total_leads }</td>

                      <td align="center">{ parseFloat( reportData.conversion_rate ).toFixed(2) }%</td>
                      <td align="center">{ parseFloat( reportData.close_rate ).toFixed(2) }%</td>
                      <td align="center">{ reportData.cost_per_lead }</td>
                    </tr>
                  
                  )}
                </tbody>
              </table>
            </div>
          </div>

        </React.Fragment>
        : <Loading />

      }
    </div>
  );
}

ReportHeader.propTypes = {
  id: PropTypes.string
};

export default ReportHeader


